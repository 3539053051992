import React, { useEffect, useState } from "react";

import galleryImage1 from "../../assets/gallery/1fef4e7e-0eb7-4498-a30f-5c081f133d38.jpg";
import galleryImage2 from "../../assets/gallery/eb0e6659-0b32-43a4-ad6a-03e6dc04e55b.jpg";
import galleryImage3 from "../../assets/gallery/156ae7c9-7d3e-4193-b8a4-0319cae9a677.jpg";
import galleryImage4 from "../../assets/gallery/e24a8817-65a5-434b-9844-0e6d89ac3949.jpg";
import galleryImage5 from "../../assets/gallery/7ff9e43a-93c0-40c0-9267-a63a3ce690c0.jpg";
import galleryImage6 from "../../assets/gallery/KUP09255.JPG";
import galleryImage7 from "../../assets/gallery/KUP09270.JPG";
import galleryImage9 from "../../assets/gallery/KUP09300.JPG";
import galleryImage10 from "../../assets/gallery/KUP09316.JPG";

import { Gallery } from "react-grid-gallery";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import "./style.scss";

const images = [
  {
    src: galleryImage1,
    original: galleryImage1,
    width: 588,
    height: 1280,
  },
  {
    src: galleryImage2,
    original: galleryImage2,
    width: 588,
    height: 1280,
  },
  {
    src: galleryImage3,
    original: galleryImage3,
    width: 1600,
    height: 1066,
  },
  {
    src: galleryImage4,
    original: galleryImage4,
    width: 1280,
    height: 958,
  },
  {
    src: galleryImage5,
    original: galleryImage5,
    width: 768,
    height: 1024,
  },
  {
    src: galleryImage6,
    original: galleryImage6,
    width: 6000,
    height: 4000,
  },
  {
    src: galleryImage7,
    original: galleryImage7,
    width: 6000,
    height: 4000,
  },
  {
    src: galleryImage9,
    original: galleryImage9,
    width: 6000,
    height: 4000,
  },
  {
    src: galleryImage10,
    original: galleryImage10,
    width: 3936,
    height: 2624,
  },
];

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

const GallerySection = () => {
  const [rowHeight, setRowHeight] = useState(300); // Default to desktop height

  const [index, setIndex] = useState(-1);

  const handleClick = (index, item) => setIndex(index);

  useEffect(() => {
    const updateRowHeight = () => {
      const width = window.innerWidth;

      if (width <= 768) {
        // Mobile
        setRowHeight(250);
      } else if (width <= 1024) {
        // iPad
        setRowHeight(400);
      } else {
        // Desktop
        setRowHeight(500);
      }
    };

    updateRowHeight(); // Set initially
    window.addEventListener("resize", updateRowHeight);

    return () => window.removeEventListener("resize", updateRowHeight);
  }, []);

  return (
    <div className="gallery-container" id="gallery">
      <div className="heading">Gallery</div>
      <Gallery
        images={images}
        rowHeight={rowHeight}
        enableImageSelection={false}
        onClick={handleClick}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default GallerySection;
